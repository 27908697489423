<template>
<div class="common">
  <Header v-if='$route.query.type != "success"' style='background:white' backType="blank" :back="!back" :backEvent="true"
    @backEvent="()=>{this.$router.back()}">
    <!-- <span slot="headText">外卖支付流程事项</span> -->
  </Header>
  <Header v-if='$route.query.type == "success"' style='background:white' backType="blank" :back="!back" :backEvent="true"
    @backEvent="()=>{this.$router.push('/orderlist')}">
    <span slot="headText">解绑支付宝</span>
  </Header>
  <div class="" v-if='$route.query.type != "success"'>
	<div class="common_title">
		<p>外卖支付流程事项</p>
		<img :src="require('../../assets/img/flowPath.png')">
	</div>
	<div class="one_piece">
		<img  :src="require('../../assets/img/common_one.png')" />
		<div class="txt">
			切勿使用同事账号下单，可能会造成无法企业支付。
		</div>
	</div>
	<div class="one_piece">
		<img  :src="require('../../assets/img/common_tow.png')" />
		<p class="txt">
			若无“企业支付”，请点击<span @click='unbound'>解绑</span>重新绑定授权支付宝企业支付。
		</p>
	</div>
	<div class="one_piece2">
		<div class="one_piece_top">
			<img  :src="require('../../assets/img/common_tree.png')" />
			<p class="txt">
				请在饿了么确认下单页面自行添加发票抬头否则商家则默认不开票。
			</p>
		</div>
		<div class="one_piece_bottom">
			<p class="title">
				智会云开票信息
			</p>
			<p class="txt">
				抬头：{{ qrCodeContent.name }}  <span class="copy" @click="copy(qrCodeContent.name)">复制</span>
			</p>
			<p class="txt">
				税号：{{ qrCodeContent.taxPayerCode }} <span class="copy" @click="copy(qrCodeContent.taxPayerCode)">复制</span>
			</p>
		</div>
	</div>
	<div class="common-buttom-btn">
		<div v-if="!back" class="common_btn" @click="()=>{this.$router.back()}">
			关闭
		</div>
		<div :class="back ? 'wid70' : ''" class="common_btn bg_theme" @click="confirm()">
			<span v-if='$route.query.type == "cancelEle"'> 取消</span>
			<span v-if='$route.query.type == "detailEle"'> 订单详情</span>
			<span v-if='$route.query.type == "flowPath"'> 下单</span>
		</div>
	</div>
  </div>
  <div v-if='$route.query.type == "success"' style='height: calc(100% - 0.4rem);
    width: 100%;
    overflow-y: auto;'>
	<div style='padding: 0.2rem;padding-top: 130px;'>
		<Svgs color="#799a07" classes="bigicon" name="iconshenqingtuikuanchenggong"></Svgs>
	</div>
	<div style='padding: 0.2rem;font-size:26px'>解绑成功</div>
	<div class="common-buttom-btn">
		<div v-if="!back" class="common_btn" @click="()=>{this.$router.push('/orderlist')}">
			关闭
		</div>
		<div :class="back ? 'wid70' : ''" class="common_btn" @click="authorize()">
			重新签约
		</div>
	</div>
  </div>
<div :class="placeOrderFlg ? 'display_block' : 'display_none'" class="all_screen">
	<div class="success_msg">
		<div v-if="placeOrderFlg">
			<Svgs color="#4AC672" class="success_img" classes="bigicon" name="iconshenqingtuikuanchenggong"></Svgs>
			<p  style="font-size: .2rem;" v-if='$route.query.type == " cancelEle"'>
				已取消完成
			</p>
			<p  style="font-size: .2rem;" v-if='$route.query.type == "flowPath"'>
				已下单成功
			</p>
		</div>
		<div v-if="!back" class="success_msg_btn theme_color" @click="()=>{$router.push('/orderList')}"> 
			关闭
		</div>
	</div>
</div>
<MessageBox 
	ref="mesg" 
	type="tips" 
	cancelTxt="联系客服" 
	:okTxt='$route.query.type == "cancelEle"?"已取消完成":"已下单成功"' 
	showCloseBtn="true" @onCancel="ihtml.telCall('01056304858')" 
	@onOk="submit" 
	:messageTxt='$route.query.type == "cancelEle" ? "是否已取消完成？如遇问题,请致电客服":"是否下单成功？如遇问题,请致电客服"' ></MessageBox>

  <BrowserLoader ref="browserLoader" @close="know" />
</div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { getCookie } from 'tiny-cookie'
import { SmartStorage } from "smart-core-util";
import { Indicator,Toast } from 'mint-ui';
export default {
    data() {
        return {
			isWx: false,
			back: false,
			flowPathFlg: false,
			placeOrderFlg: false,
            qrCodeContent: {
				name:'智会云(天津)会议展览有限公司',
				taxPayerCode:'91120222MA069UF33M'
			},
        };
    },
    computed: {
        ...mapGetters([ "orderDetail",'mealRules','memsg','event'])
    },
    async created(){
		this.queryCompany()
		var u = navigator.userAgent;
		if (/MicroMessenger/gi.test(u)) {
			this.isWx = true;
			return;
		}
        this.back = SmartStorage.get("placeOrder")
    },
    async mounted(){
	
    },
    watch: {
        showHeight: function() {
            if (this.docmHeight !== this.showHeight) {
                this.hideshow = true
            } else {
                this.hideshow = false
            }
        }
    },
    methods: {
		copy(txt){
			let input = document.createElement('input')
			input.setAttribute('readonly','readonly')
			input.setAttribute('value',txt)
			input.style.top = '-999999999999px'
			input.style.left = '-999999999999px'
			input.style.opacity = 0
			input.style.position = 'fixed'
			document.body.appendChild(input)
			input.select()
			document.execCommand('Copy')
			Toast('复制成功')
			input.remove() 
		},
        // 查询中智商展二维码信息
        queryCompany: async function() {
            const params = {
                proposalId: this.event.proposalId
            };
            miceService.invoiceGetCompany(params).then(res => {
                if (res && res.success) {
                    this.qrCodeContent = res.content;
                }
            });
        },
		submit(){
            Indicator.open({
                text: '查询中...',
                spinnerType: 'fading-circle'
            });
            let params = {
                businessId: ''
            }
			if(this.back){
				params.businessId = this.$route.query.itemId
			}else{
				params.businessId = this.orderDetail.itemId
			}
			let that = this
            setTimeout(function () {
                Indicator.close()
				miceService.CheckPayInfo(params).then(res => {
					if(res && res.success){
						if(res.code != 1001){
							that.placeOrderFlg = true
							return
						}
						that.$refs.mesg.openPop();
					}
				})
            }, 1000);
		},
		know(){
            if(this.isWx && this.$route.query.type == "flowPath"){
				this.$refs.mesg.openPop()
                return;
            }
		},
		confirm(){
            if(this.isWx){
				this.$refs.browserLoader.openPop(this.$route.query.aliURL)
                return;
            }
			if(this.$route.query.type == "flowPath"){this.$refs.mesg.openPop();}
			window.location.href = this.$route.query.aliURL
		},
		unbound(){
			miceService.Get(`/rpc/pay/Alipay/MemberUnBind?userId=${getCookie('userId') || SmartStorage.get("userId")}&sid=${this.mealRules.sid}&tenantId=${getCookie('tenant_id')}`).then(res=>{
				if(res&&res.success){
					this.$router.push("/common?type=success")
				}
				if(res&&res.msg){
					this.toast(res.msg)
				}
			})
		},
		authorize(){
			let path = `/alipayAuthorize?authorize=true&sessionId=${SmartStorage.get('sessionId')}&sid=${this.mealRules.sid}&userId=${this.memsg.userId}&authMobile=${SmartStorage.get('authMobile')}&rnd=${new Date().getTime()}`
			this.$router.push({
				path: path,
			});
		}
    }
};
</script>
<style lang="scss">
.common{
	
.common_title{
	width: 100%;
	p{
		color: #13161B;
		text-align: left;
		font-size: .24rem;
		font-weight: bold;
		margin-left: .12rem;
	}
	display: flex;
	align-items: center;
	border-bottom: 2px solid #f5f5f5f5;
	margin-bottom: .3rem;
	img{
		margin-left: .3rem;
		width: 20%;
	}
}
.one_piece{
	width: 90%;
	background: #f5f5f5f5;
	text-align: left;
	padding: 0 5%;
	padding-bottom: .15rem;
	display: flex;
	margin-top: .1rem;
	border-radius: 5px;
	img{
		width: 7%;
		height: 10vw;
	}
	.txt{
		text-align: left;
		width: 85%;
		font-family: PingFang SC;
		font-weight: 500;
		color: #13161B;
		margin-left: .15rem;
		margin-top: .1rem;
		span{
			display: inline-block;
			background: rgba(56, 117, 254, .15);
			color: var(--themeColor);
			font-weight: 500;
			padding: 0 .06rem;
			border-radius: 3px;
			margin: 0 .08rem;
		}
	}
}

.one_piece2{
	width: 90%;
	background: #f5f5f5f5;
	text-align: left;
	padding: 0 5%;
	padding-bottom: .15rem;
	margin-top: .1rem;
	border-radius: 5px;
	.one_piece_top{
		width: 100%;
		text-align: left;
		display: flex;
		img{
			width: 7%;
			height: 10vw;
		}
		.txt{
			text-align: left;
			width: 85%;
			font-family: PingFang SC;
			font-weight: 500;
			color: #13161B;
			margin-left: .15rem;
			margin-top: .1rem;
			span{
				display: inline-block;
				background: rgba(56, 117, 254, .15);
				color: var(--themeColor);
				font-weight: 500;
				padding: 0 .06rem;
				border-radius: 3px;
				margin: 0 .08rem;
			}
		}
	}
	.one_piece_bottom{
		margin-top: .2rem;
		.title{
			text-align: left;
			font-weight: bold;
			font-size: .16rem;
		}
		.txt{
			text-align: left;
			display: flex;
			justify-content: space-between;
			margin-top: .1rem;
			.copy{
				width: .45rem;
				height: .2rem;
				border-radius: 5px;
				background: rgba(56, 117, 254, .15);
				margin-left: .1rem;
				color: var(--themeColor);
				border: none;

			}
		}
	}

}
.common-buttom-btn{
	width: 95%;
	position: absolute;;
	bottom: 50px;
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
}
.common_btn{
	width: 47%;
	height: 45px;
	border: 0.5px solid #E6E7E9;
	margin: 0 auto;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	font-size: 14px;
	color: #90939A;
}
.bg_theme{
	background: var(--themeColor);
	color: #ffffff;
}
.all_screen{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	background-color: rgba(121, 121, 121, 0.3);
	z-index: 1;
	.success_msg{
		width: 80%;
		height: 80vw;
		background: white;
		margin: 50% auto;
		border-radius: 10px;
		padding-top: .2rem;
		.success_img{
			margin-top: .2rem;
		}
		.success_msg_btn{
			width: 80%;
			height: 45px;
			margin: 0 auto;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
			font-size: .16rem;
			box-shadow: 0px 4px 13px 0px rgba(26,109,202,0.2);
			color: #ffffff;

		}
	}
}

.theme_color{
	background: var(--themeColor);
}
.colorFFF{
	color: #fff
}
.display_block{
	display: block;
}
.display_none{
	display: none;
}
.WXShadow {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 40px;
	z-index: 22000;
	img {
		width: 36%;
		height: 25%;
		float: right;
		margin-right: 0.2rem;
		margin-top: 0.2rem;
	}
	.shadowtext {
		font-size: 0.2rem;
		position: fixed;
		top: 2rem;
		width: 80%;
		background: #fff;
		left: 5%;
		text-align: center;
		padding: 0.15rem;
		padding-bottom: 0.25rem;
		border-radius: 0.1rem;
		box-shadow: 0 0 9px 0px #2a292966;
	div {
		font-weight: 600;
		margin-bottom: 0.2rem;
	}
	span {
		font-size: 0.16rem;
	}
	a {
		display: block;
		width: 60%;
		font-size: 0.16rem;
		background: var(--themeColor);
		color: #fff;
		padding: 0.12rem 0.48rem;
		border-radius: 50px;
		margin: 0 auto;
		margin-top: 0.5rem;
	}
	}
}

}
.bigicon{
	width: 1.2rem!important;
	height: 1.2rem!important;
}
</style>